import React, { useState, useRef, useEffect } from "react";
import "bulma/css/bulma.css";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import ModalWindow from "./components/ModalWindow";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import Loading from "./components/Loading";

const defaultText = "";
let interval = null;

const apiEndpoint = "https://api.thegauntlet.ca/volunteers/email";

const google_ver =
  "674216813780-77lnc10kkd30huovd4l1c6oqfce39ir9.apps.googleusercontent.com";

const apiKey = "C3TZZPxHQh4Smjva9NoNm3qhnHkmfDR96J3oh4YI";

function App() {
  const [markdown, setMarkdown] = useState(defaultText);
  const markdownTarget = useRef(null);
  const [subject, setSubject] = useState("Subject");
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [sendTestLoading, setSendTestLoading] = useState(false);
  const [sendBulkLoading, setSendBulkLoading] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [numberOfVolunteers, setNumberOfVolunteers] = useState("-");
  const [profileLoading, setProfileLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const email = useRef(null);
  const gid = useRef(null);
  const buttonClass = useRef(null);
  const [accessDenied, setAccessDenied] = useState(false);
  const [font, setFont] = useState(18);

  const message = useRef(null);
  const messageClass = useRef(null);
  const modalButtonLabel = useRef(null);
  const is_sample = useRef(null);

  const showModal = (sampleOrNot, buttonLabel) => {
    is_sample.current = sampleOrNot;
    modalButtonLabel.current = buttonLabel;
    buttonClass.current = sampleOrNot ? "is-primary" : "is-warning";
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const showMessage = (msg, cls) => {
    message.current = msg;
    messageClass.current = cls;
    setMessageVisible(true);
    setTimeout(() => setMessageVisible(false), 2000);
  };

  const authenticate = () => {
    setProfileLoading(true);
    axios
      .post(
        `${apiEndpoint}/authenticate`,

        {},
        {
          headers: {
            "Content-Type": "application/json",
            em: email.current,
            "g-id": gid.current,
            "x-api-key": apiKey,
          },
        }
      )
      .then((res) => {
        setLoggedIn(true);
        getDraft();
        if (accessDenied) {
          setAccessDenied(false);
        }
      })
      .catch((err) => {
        setAccessDenied(true);
      })
      .then(() => {
        setProfileLoading(false);
      });
  };

  const successIn = (response) => {
    console.log("success in")
    email.current = response.profileObj.email;
    gid.current = response.tokenId;

    authenticate();
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const failedIn = (response) => {
    console.log("failed in")
    setLoggedIn(false);
  };

  const sendEmail = (subject, is_sample) => {
    setModalVisible(false);
    setSubject(subject);
    if (is_sample) {
      setSendTestLoading(true);
    } else {
      setSendBulkLoading(true);
    }
    axios
      .post(
        `${apiEndpoint}/send`,
        {
          body: markdownTarget.current.innerHTML,
          subject,
          is_sample,
          email: email.current,
        },
        {
          headers: {
            "Content-Type": "application/json",
            em: email.current,
            "g-id": gid.current,
            "x-api-key": apiKey,
          },
        }
      )
      .then((res) => {
        showMessage("Email Successfully Sent!", "is-success");
      })
      .catch((err) => {
        showMessage("Email Failed!", "is-danger");
      })
      .then(() => {
        saveDraft(subject, true);
        if (is_sample) {
          setSendTestLoading(false);
        } else {
          setSendBulkLoading(false);
        }
      });
  };

  const getDraft = () => {
    setDraftLoading(true);
    axios
      .post(
        `${apiEndpoint}/get-draft`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            em: email.current,
            "g-id": gid.current,
            "x-api-key": apiKey,
          },
        }
      )
      .then((res) => {
        const result = JSON.parse(res.data.body);
        setMarkdown(result.draft.body);
        setSubject(result.draft.subject);
        setFont(result.draft.font);
        setNumberOfVolunteers(result.number_of_volunteers);
      })
      .catch((err) => console.log(err))
      .then(() => {
        setDraftLoading(false);
      });
  };

  const saveDraft = (currentSubject, noMessage) => {
    currentSubject = currentSubject ? currentSubject : subject;
    setSaveDraftLoading(true);
    axios
      .post(
        `${apiEndpoint}/save-draft`,
        {
          body: markdown,
          subject: currentSubject,
          font,
        },
        {
          headers: {
            "Content-Type": "application/json",
            em: email.current,
            "g-id": gid.current,
            "x-api-key": apiKey,
          },
        }
      )
      .then((res) => {
        if (!noMessage) {
          showMessage("Draft Successfully Saved!", "is-success");
        }
      })
      .catch((err) => {
        if (!noMessage) {
          showMessage("Draft Failed!", "is-danger");
        }
      })
      .then(() => setSaveDraftLoading(false));
  };

  const changeMarkdown = (event) => {
    setMarkdown(event.target.value);
  };

  const LinkRenderer = (props) => {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    );
  };

  return (
    <div className="container">
      {modalVisible ? (
        <ModalWindow
          subject={subject}
          is_sample={is_sample.current}
          buttonLabel={modalButtonLabel.current}
          buttonClass={buttonClass.current}
          hideModal={hideModal}
          handler={sendEmail}
        />
      ) : null}

      {messageVisible ? (
        <article className={`message ${messageClass.current}`}>
          <div className="message-body">{message.current}</div>
        </article>
      ) : null}

      {!loggedIn ? (
        <div
          className="has-text-centered"
          style={{
            width: "640px",
            maxWidth: "100%",
            margin: "0 auto",
            borderLeft: "1px solid #e6d2d1",
            borderRight: "1px solid #e6d2d1",
          }}
        >
          <img
            src="https://res.cloudinary.com/thegauntlet/image/upload/v1609536667/TheG_Header_3_lxn2gl.jpg"
            style={{ display: "block" }}
          />
          <p style={{ marginBottom: "16px", paddingTop: "30px" }}>
            <img
              src="https://res.cloudinary.com/thegauntlet/image/upload/v1609532701/GauntletLetterhead_Grey_dh9afz.png"
              style={{ width: "256px" }}
            />
          </p>
          <GoogleLogin
            clientId={google_ver}
            buttonText="Login with Google"
            onSuccess={successIn}
            onFailure={failedIn}
            cookiePolicy={"single_host_origin"}
            isSignedIn={true}
            className="google-login-btn"
          />
          <br />
          {profileLoading ? <Loading /> : null}
        </div>
      ) : null}

      {accessDenied && !profileLoading ? (
        <p
          className="has-text-danger has-text-centered"
          style={{ marginTop: 10 }}
        >
          Access Denied!
        </p>
      ) : null}

      {loggedIn ? (
        <div className="columns is-mobile is-multiline">
          <div className="column is-full has-text-left" id="header">
            <div className="columns is-multiline">
              <div
                className="column  is-half-tablet is-full-mobile has-text-centered-mobile"
                id="button-holder"
              >
                <button
                  className={`button is-success is-small is-light ${
                    saveDraftLoading ? "is-loading" : ""
                  }`}
                  onClick={() => saveDraft()}
                >
                  Save Draft
                </button>
                <button
                  className={`button is-link is-small is-light ${
                    sendTestLoading ? "is-loading" : ""
                  }`}
                  onClick={() => showModal(true, "Send test email to myself")}
                >
                  Send Test
                </button>

                <button
                  className={`button is-warning is-small is-light ${
                    sendBulkLoading ? "is-loading" : ""
                  }`}
                  disabled={false}
                  onClick={() =>
                    showModal(false, "Send Email to All Volunteers")
                  }
                >
                  Send To All
                </button>
                <div className="buttons has-addons">
                  <button
                    className="button is-light"
                    onClick={() => setFont(font - 1)}
                  >
                    A-
                  </button>
                  <button
                    className="button is-light"
                    onClick={() => setFont(font + 1)}
                  >
                    A+
                  </button>
                </div>
              </div>
              <div className="column  is-half-tablet is-full-mobile has-text-right has-text-centered-mobile">
                <p className="has-text-white" style={{ fontSize: "1.4em" }}>
                  Hi there!
                </p>
                <p className="help has-text-white">
                  {/* It takes about{" "}
                  {numberOfVolunteers === "-"
                    ? "-"
                    : parseInt(numberOfVolunteers / 12) + 2}{" "}
                  seconds to send an email&nbsp;&nbsp; */}
                  <span>
                    {loggedIn ? (
                      <GoogleLogout
                        clientId={google_ver}
                        render={(renderProps) => (
                          <a
                            href="#"
                            className="logout-btn"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            style={{
                              display: "inline-block",
                              textAlign: "center",
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            (Logout)
                          </a>
                        )}
                        onLogoutSuccess={logout}
                      ></GoogleLogout>
                    ) : null}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="column is-full"
            style={{ borderTop: "3px solid #5b637f" }}
          >
            <div className="columns is-multiline">
              <div
                className="column is-half-tablet is-full-mobile side-column"
                id="markdown"
              >
                <textarea
                  placeholder="Start your email here"
                  style={{ fontSize: font + "px" }}
                  onChange={changeMarkdown}
                  value={draftLoading ? "Loading..." : markdown}
                ></textarea>
              </div>
              <div
                className="column is-half-tablet is-full-mobile side-column"
                id="preview"
                ref={markdownTarget}
                style={{ fontSize: font + "px" }}
              >
                <ReactMarkdown
                  source={markdown}
                  escapeHtml={false}
                  renderers={{ link: LinkRenderer }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
