import React, { useState } from "react";
import Modal from "./Modal";

const ModalWindow = ({
  subject,
  is_sample,
  handler,
  buttonLabel,
  buttonClass,
  hideModal,
}) => {
  const [innerSubject, setInnerSubject] = useState(subject);
  const [password, setPassword] = useState("");
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);

  const handleClick = () => {
    if (password === "staff;staff") {
      handler(innerSubject, is_sample);
    } else {
      setPasswordIncorrect(true);
    }
  };

  return (
    <Modal>
      <div className="has-text-centered">
        <label className="label has-text-left" htmlFor="subject">
          Email Subject
        </label>
        <input
          type="text"
          className="input"
          id="subject"
          value={innerSubject}
          style={{ marginBottom: 16 }}
          onChange={(event) => setInnerSubject(event.target.value)}
        />
        <label className="label has-text-left" htmlFor="password">
          Password
        </label>
        <input
          type="password"
          className={`input ${passwordIncorrect ? "is-danger" : ""}`}
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        {passwordIncorrect ? (
          <p className="help is-danger has-text-left">Password is incorrect!</p>
        ) : null}
        <button
          style={{ marginTop: 26, marginRight: 16 }}
          className={`button ${buttonClass}`}
          onClick={handleClick}
        >
          {buttonLabel}
        </button>
        <button
          style={{ marginTop: 26 }}
          className="button"
          onClick={hideModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ModalWindow;
